@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .text {
    @apply antialiased font-ubuntu;
  }

  .h2 {
    @apply text text-2xl mb-5 font-medium  text-textPrimary;
  }

  .p {
    @apply text mb-10 text-textPrimary;
  }

  .link {
    @apply p underline text-blue-600 ;
  }

  .p-primary {
    @apply text text-textPrimary;
  }

  .btn {
    @apply text py-2 px-4  font-medium rounded-lg shadow-md w-32;
  }

  .btn-primary {
    @apply btn  bg-btnPrimary text-white hover:bg-btnPrimaryActive;
  }

  .btn-secondary {
    @apply w-32 text py-2 px-4 border font-medium  border-btnPrimary text-btnPrimary hover:opacity-70 rounded-lg;
  }

  .btn-disabled {
    @apply btn bg-gray-500 text-white hover:bg-gray-700;
  }

  .input-field {
    @apply shadow appearance-none w-full h-10 px-3 text-base placeholder-gray-300 border rounded-lg;
  }

  .textarea {
    @apply input-field py-1 h-14;
  }
}

select::before {
  background-color: aliceblue;
}
